<template>
    <div class="g-head">
        <div :style="isDetailsSearch?{borderBottom:'3px solid #f4f4f4'}:{}" class="wal">
            <!--logo内容-->
            <div class="flexAlignCenter TabBarContentBox">
                <div @click="routeTo({path:'/'})" class="flexAlignCenter TabBarContentBoxLeftBox">
                    <img class="logoImg notNull" src="@/assets/img/TabBar/tabbarImg.png">
                    <img class="logoImg isNull" src="@/assets/img/TabBar/tabbarImgActive.png">
                    <div class="TabBarContentBoxLeft">
                        <h2>宝可梦图鉴岛</h2>
                        <h3>PokemonQuery</h3>
                    </div>
                </div>
                <div style="justify-content: space-between" class="TabBarContentBoxCenter">
                    <div class="flexAlignCenter">
                        <div @click="routeTo(item)" class="flexAlignCenter TabBarContentBoxCenterText" v-for="(item,index) in tabDatas">
                            <h4>{{item.name}}</h4>
                            <img class="isNotNone" v-if="item.isChild!=null" src="@/assets/img/TabBar/downward.png">
                            <img class="isNone" v-if="item.isChild!=null" src="@/assets/img/TabBar/downwardActive.png">
                            <div v-if="item.isChild!=null" class="flexAlignCenterSonbox">
                                <div class="flexAlignCenterSonboxFlex" v-for="(child,childIndex) in item.isChild">
                                    <p>{{child}}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!--手机端按钮-->
                <div @click="isDetailsSearch = !isDetailsSearch" class="rightScoreBox">
                    <img v-show="!isDetailsSearch" src="@/assets/img/TabBar/more.png">
                    <img v-show="isDetailsSearch" src="@/assets/img/TabBar/close.png">
                </div>
            </div>
            <div v-if="screenWidth < 500" :style="isDetailsSearch?{display:'block'}:{display:'none',opacity:0}" :class="isDetailsSearch ?'allIphoneBox':'allIphoneBox'">
                <div @click="routeTo(item)" class="iphoneBox" v-for="(item,index) in tabDatas">
                    <div class="iphoneBoxItem">
                        <div class="iphoneBoxSonBox">
                            <div class="iphoneBoxSonBoxTopBox">
                                <p>{{item.name}}</p>
                                <img :class="item.isOpen?'rotateActive':'rotate'" @click="item.isOpen = !item.isOpen" v-if="item.isChild!=null" src="@/assets/img/TabBar/bottomJ.png">
                            </div>
                            <div v-if="item.isChild!=null && item.isOpen" class="iphoneBoxSonBoxBottomBox">
                                <div :key="sonIndex" v-for="(son,sonIndex) in item.isChild">
                                    <p>{{son}}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "TabBar",
        data(){
            return{
                tabDatas:[
                    {name:"论坛社区",isChild:null},
                    {name:"宠物攻略",isChild:null},
                    {name:"我的队伍",isChild:null},
                    {name:"信息查询",isChild:["特性查询","道具查询","宠物查询","蛋组查询"],isOpen:false},
                    {name:"地区图鉴",isChild:null},
                    {name:"全国图鉴",isChild:null,path:'/illustratedHandbook/national'},
                ],
                isDetailsSearch:false,
                screenWidth:0,
            }
        },
        methods:{
            handleResize() {
                console.log("变化")
                this.screenWidth = window.innerWidth;
                console.log(this.screenWidth)
            },
            routeTo(item){
                if(typeof item.path != 'undefined'){
                    this.$router.push(item.path)
                }
                if(this.screenWidth < 500){
                    this.isDetailsSearch = false
                }
            }
        },
        mounted() {
            window.addEventListener('resize', this.handleResize);//监听屏幕变化
        },
        created() {
            this.screenWidth = window.innerWidth;
        }
    }
</script>

<style scoped>

    body.show a, body.show .transition, body.show .index-flow .title dl dd .layer, .index-flow .title dl dd body.show .layer, body.show .g-nav2, body.show .g-nav, body.show .g-head .btn-list .search .input, .g-head .btn-list .search body.show .input, body.show .g-head .btn-list, .g-head body.show .btn-list, body.show :before, body.show :after, body.show img {
        transition: All 0.5s ease;
        -webkit-transition: All 0.5s ease;
        -moz-transition: All 0.5s ease;
        -o-transition: All 0.5s ease;
    }
    .rightScoreBox{
        background-image: url("../assets/img/TabBar/rightScore.png");
        background-size: 100% 100%;
    }
</style>
