<template>
    <!--轮播图组件-->
    <div class="CarouseBox">
        <img v-for="(item, index) in arrayCarouse" :key="index" :src="item.url" :class="['CarouseBoxGiant', { 'is-active': index === activeIndex }]"/>
        <div class="CarouseBoxBottom">
            <div class="CarouseBoxBorderBox">
                <label
                        v-for="(item, index) in arrayCarouse"
                        :key="index"
                        @click="BtnClick(index)"
                        :style="activeIndex === index ? { backgroundColor: '#dcc55a' } : {}"
                        class="CarouseBoxBorder"
                ></label>
            </div>
            <p class="carouseText">{{ arrayCarouse[activeIndex].title }}</p>
        </div>
        <div @click="changeMove(0)" style="left:20px" class="CarouseBoxGiantIconBox">
            <img src="@/assets/img/CarouseIImage/left.png">
        </div>
        <div @click="changeMove(1)" style="right:20px" class="CarouseBoxGiantIconBox">
            <img src="@/assets/img/CarouseIImage/right.png">
        </div>
    </div>
</template>

<script>
    export default {
        name: "CarouselImage",
        data() {
            return {
                arrayCarouse: [
                    { url: require("@/assets/img/Home/swiper1.jpg"), title: "帝牙卢卡&帕路奇亚降临！～宣告新月来临的达克莱伊～”活动确定举办" },
                    { url: require("@/assets/img/Home/swiper2.jpg"), title: "宝可梦朱紫发售啦～ 大家冲冲冲!" },
                    { url: require("@/assets/img/Home/swiper3.jpg"), title: "闪耀的路卡利欧来相助！来获得大师球，并捕捉传说的宝可梦吧！举办中！" },
                    { url: require("@/assets/img/Home/swiper4.jpg"), title: "快来一场紧张刺激的排位对战吧～" }
                ],
                activeIndex: 0,
                changeMs: 8000
            };
        },
        methods: {
            BtnClick(index) {
                this.activeIndex = index;
            },
            activeIndexNumChange() {
                this.activeIndex + 1 >= this.arrayCarouse.length ? this.activeIndex = 0 : this.activeIndex++;
            },
            changeMove(score){
                if(score == 0){//上一张
                    this.activeIndex == 0 ? this.activeIndex = this.arrayCarouse.length - 1 : this.activeIndex = this.activeIndex - 1
                }else{//下一张
                    this.activeIndex == this.arrayCarouse.length - 1 ?this.activeIndex = 0: this.activeIndex = this.activeIndex+1
                }
            }
        },
        created() {
            setInterval(this.activeIndexNumChange, this.changeMs);
        }
    };
</script>

<style scoped>
    .CarouseBox {
        background-color: rgba(0, 0, 0, 0.5); /* 半透明的黑色遮罩 */
        height: 100%;
        width: 100%;
        position: relative;
        overflow: hidden; /* 防止过渡过程中的溢出 */
    }

    .CarouseBoxGiant {
        position: absolute;
        width: 100%;
        height: 100%;
        opacity: 0;
        transition: opacity 1s ease-in-out; /* 添加平滑过渡效果 */
    }

    .CarouseBoxGiant.is-active {
        opacity: 1;
    }

    .CarouseBoxBottom {
        align-items: center;
        width: 100%;
        position: absolute;
        bottom: 15vh;
        display: flex;
        flex-direction: column;
    }

    .CarouseBoxBorderBox {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .CarouseBoxBorder {
        cursor: pointer;
        border-radius: 0.3rem;
        width: 10px;
        height: 10px;
        background: #ffffff;
        margin: 0px 5px;
    }

    .CarouseBoxBottom p {
        color: #ffffff;
    }
    .CarouseBoxGiantIconBox{
        cursor: pointer;
        opacity: 0.5;
        display: flex;
        align-items: center;
        justify-content: center;
        border-bottom: none;
        border-radius: 50%;
        top: calc(50% - 20px);
        position: absolute;
        height: 40px;
        width: 40px;
    }
    .CarouseBoxGiantIconBox:hover{
        opacity: 0.8;
    }
    .carouseText{
        font-weight: 500;
        margin-top: 1rem;
        font-family: AliKai;
        font-size: 1.5rem;
    }
</style>
