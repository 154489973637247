import { createRouter, createWebHistory } from 'vue-router'
import Home from '@/views/Home.vue'
import National from '@/views/illustratedHandbook/national.vue'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta:{
      keepAlive: true // 表示这个页面需要被缓存
    }
  },
  {
    path:'/illustratedHandbook',
    children:[
      {
        path:'national',
        component:National,
      }
    ]
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
