<template>
    <div class="CarouseIImageLate">
        <div class="box">
            <ul ref="ulDom" @mouseenter="pause" @mouseleave="resume">
                <li v-for="item in 2">
                    <div v-for="(data,index) in arrayDatas" class="cartBox">
                        <img :src="`https://www.pokemonquery.com/img/cart/${data}.png`">
                        <p>测试宠物名称</p>
                    </div>
                </li>
            </ul>
        </div>
    </div>
</template>

<script setup>
    import { ref, onMounted,onUnmounted} from 'vue'
    const ulDom = ref(null)
    let animationId = null
    let isPaused = false
    let arrayDatas = []
    for(let i=0;i<5;i++){
        arrayDatas.push(Math.floor(Math.random() * 1025))
    }

    onMounted(() => {
        animation()
    })
    onUnmounted(()=>{
        pause()
    })
    const pause = () => {
        cancelAnimationFrame(animationId)
        isPaused = true
    }

    const resume = () => {
        if (!isPaused) return
        animationId = requestAnimationFrame(animation)
        isPaused = false
    }
    let position = 0
    const animation = () => {
        const ulWidth = ulDom.value.offsetWidth
        const animate = () => {
            position -= 2
            if (position <= -(ulWidth / 2)) {
                position = 0
            }
            ulDom.value.style.transform = `translateX(${position}px)`
            animationId = requestAnimationFrame(animate)
        }
        animate()
    }
</script>

<style scoped>
    .box{
        width: 100vw;
        height: 300px;
        overflow: hidden;
    }
    .box ul{
        width: 200%;
        height: 100%;
    }
    .box li{
        width: 100vw;
        background-color: rgb(255, 255, 255);
        height: 100%;
        float: left;
        list-style: none;
        display: flex;

    }
</style>
