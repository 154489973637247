<template>
    <div class="national">
        <div class="national-decoration"></div>
        <div class="national-headBox">
            <div class="national-headBox-contentBox">
                <div class="national-headBox-titleText">
                    <h2>全国图鉴</h2>
                    <div class="national-headBox-titleFuText">
                        <div @click="allTypesSelect(index)" :key="index" v-for="(item,index) in allTypes">
                            <h4 :style="allActive == index?{color:'#6510ad'}:{}">{{item}}</h4>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "national",
        data(){
            return{
                allActive:0,
                allTypes:["所有地区","关都地区","成都地区","丰缘地区","神奥地区","合众地区","卡洛斯地区","阿罗拉地区","伽勒尔地区","帕底亚地区"]
            }
        },
        methods:{
            allTypesSelect(index){
                this.allActive = index
            }
        }
    }
</script>

<style scoped>

</style>
