<template>
    <div class="Home">
        <!--轮播图-->
        <div class="swiper-wrapper">
            <CarouselImage></CarouselImage>
        </div>
        <!--下方搜索栏-->
        <div class="searchBox">
            <div class="searchBoxDiv">
                <div class="searchBoxFlexInput">
                    <input type="text" placeholder="快速搜索 Corresponding Announcement">
                </div>
                <div class="searchImg">
                </div>
            </div>
            <div class="hot-sarch">
                <div class="hot-search-title">
                    <h3>高频搜索内容  ></h3>
                    <p v-for="(item,index) in hotDatas">{{item}}</p>
                </div>
            </div>
        </div>
        <!--三列布局-->
        <div class="SanLie">
            <div class="SanLieItemBox Left">
                <div class="SanLieItemBoxTitle">
                    <h3>社区热点</h3>
                    <h3>HOT TOPICS</h3>
                </div>
                <img src="@/assets/img/Home/shequ.jpg">
                <!--下方内容-->
                <div v-for="(item,index) in hotShequ" class="SanLieItemBoxContent">
                    <div class="SanLieItemBoxContentBorder">
                        {{item}}
                    </div>
                </div>
            </div>
            <div class="SanLieItemBoxCenter">
                <CarouseIImageCenter></CarouseIImageCenter>
            </div>
            <div class="SanLieItemBox Right">
                <div class="SanLieItemBoxTitle">
                    <h3>宠物攻略</h3>
                    <h3>PET GUIDE</h3>
                </div>
                <img src="@/assets/img/Home/cwgl.jpg">
                <!--下方内容-->
                <div v-for="(item,index) in hotGonglue" class="SanLieItemBoxContent">
                    <div class="SanLieItemBoxContentBorder">
                        {{item}}
                    </div>
                </div>
            </div>
        </div>
        <!--每日推荐宝可梦-->
        <div class="DailyRecommendations">
            <div class="DailyRecommendationsTitleBox">
                <div>
                    <h3>RECOMMEND</h3>
                    <h1>每日推荐</h1>
                </div>
                <p class="hr"></p>
                <div class="DailyRecommendationsTitleBoxContent">
                    <p>快来看看今天属于你的幸运宝可梦吧～</p>
                    <p>Come and take a look at your lucky Pokémon today</p>
                </div>
            </div>
            <CarouseIIamgeLate></CarouseIIamgeLate>
        </div>
    </div>
</template>

<script>
    import CarouseIIamgeLate from "@/components/CarouseIIamgeLate";
    import CarouseIImageCenter from "@/components/CarouseIImageCenter";
    import CarouselImage from "@/components/CarouselImage";
    export default {
        name: "Home",
        components: {CarouselImage,CarouseIImageCenter,CarouseIIamgeLate},
        data(){
            return{
                hotDatas:[
                    "路卡利欧","喷火龙","超梦","皮卡丘"
                ],
                hotShequ:[
                    "宝可梦们在各个舞台上大量出现，呈现出一片庆祝氛围！",
                    "来查看“后篇：蓝之圆盘”的庆祝发布活动吧！",
                    "皮皮大量出现”确定举办！来享受赏月派对吧！",
                    "索财灵（宝箱形态）的活动特设太晶团体战举办中！"
                ],
                hotGonglue:[
                    "炮台推队破壳超级水箭龟「 水箭龟 」",
                    "无敌催眠火觉蒜头王八「 妙蛙花 」",
                    "无脑推队蕾冠王「 蕾冠王-黑马的样子 」",
                    "晴天队大杀器超高物攻手「 达摩狒狒 」"
                ]
            }
        }
    }
</script>

<style scoped>
    .searchImg{
        cursor: pointer;
        background-size: 100% 100%;
        background-repeat: no-repeat;
        width: 25px;
        height: 25px;
        margin: 20px;
        background-image: url("../assets/img/Home/search.png");
    }
</style>
