<template>
    <!--轮播图组件-->
    <div class="CarouseBox">
        <img v-for="(item, index) in arrayCarouse" :key="index" :src="item.url" :class="['CarouseBoxGiant', { 'is-active': index === activeIndex }]"/>
        <div class="centerBox">
            <div class="centerBoxTitle">
                <p>「 </p> <h2>最新活动 NEWACTIVITY</h2>  <p> 」</p>
            </div>
            <div class="centerBoxTitleZheng">
                <h1>{{arrayCarouse[activeIndex].title}}</h1>
            </div>
            <div class="centerBoxContent">
                <p>{{arrayCarouse[activeIndex].content}}</p>
            </div>
        </div>
        <div @click="changeMove(0)" style="left:5vw" class="CarouseBoxGiantIconBox">
            <img src="@/assets/img/CarouseIImage/theLeft.png">
        </div>
        <div @click="changeMove(1)" style="right:5vw" class="CarouseBoxGiantIconBox">
            <img src="@/assets/img/CarouseIImage/theRight.png">
        </div>
    </div>
</template>

<script>
    export default {
        name: "CarouselImageCenter",
        data() {
            return {
                arrayCarouse: [
                    { url: require("@/assets/img/Home/hd1.jpg"), title: "来查看“后篇：蓝之圆盘”的庆祝发布活动吧！",content:"公开《宝可梦 朱／紫 零之秘宝》 “后篇：蓝之圆盘”的庆祝发布活动日程！"},
                    { url: require("@/assets/img/Home/hd2.jpg"), title: "原创网络动画《帕底亚的课后时光》于9月6日公开！",content: "在《宝可梦 朱／紫》的舞台——帕底亚地区展开的原创故事。为您献上由WIT STUDIO制作的高水准动画作品。"},
                    { url: require("@/assets/img/Home/hd3.jpg"), title: "挑战波荡水、铁斑叶的活动特设太晶团体战确定举办！",content:"玩家在《宝可梦 朱》的活动特设太晶团体战中可挑战波荡水，而在《宝可梦 紫》的活动特设太晶团体战中则可挑战铁斑叶在活动特设太晶团体战中出现的波荡水是水的太晶属性，而铁斑叶则是超能力的太晶属性。这是挑战未曾遇见的神秘宝可梦们的大好时机。"},
                ],
                activeIndex: 0,
                changeMs: 8000
            };
        },
        methods: {
            BtnClick(index) {
                this.activeIndex = index;
            },
            activeIndexNumChange() {
                this.activeIndex + 1 >= this.arrayCarouse.length ? this.activeIndex = 0 : this.activeIndex++;
            },
            changeMove(score){
                if(score == 0){//上一张
                    this.activeIndex == 0 ? this.activeIndex = this.arrayCarouse.length - 1 : this.activeIndex = this.activeIndex - 1
                }else{//下一张
                    this.activeIndex == this.arrayCarouse.length - 1 ?this.activeIndex = 0: this.activeIndex = this.activeIndex+1
                }
            }
        },
        created() {
            setInterval(this.activeIndexNumChange, this.changeMs);
        }
    };
</script>

<style scoped>
    .CarouseBox {
        color: #000000;
        height: 100%;
        width: 100%;
        position: relative;
        overflow: hidden; /* 防止过渡过程中的溢出 */
    }

    .CarouseBoxGiant {
        position: absolute;
        width: 100%;
        height: 40%;
        opacity: 0;
        transition: opacity 1s ease-in-out; /* 添加平滑过渡效果 */
    }

    .CarouseBoxGiant.is-active {
        opacity: 1;
    }

    .CarouseBoxBottom {
        align-items: center;
        width: 100%;
        position: absolute;
        bottom: 10vh;
        display: flex;
        flex-direction: column;
    }

    .CarouseBoxBorderBox {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .CarouseBoxBorder {
        cursor: pointer;
        border-radius: 0.3rem;
        width: 10px;
        height: 10px;
        background: #ffffff;
        margin: 0px 5px;
    }

    .CarouseBoxBottom p {
        color: #000000;
    }
    .CarouseBoxGiantIconBox{
        cursor: pointer;
        opacity: 0.5;
        display: flex;
        align-items: center;
        justify-content: center;
        border-bottom: none;
        border-radius: 50%;
        bottom: 10%;
        position: absolute;
        height: 40px;
        width: 40px;
    }
    .CarouseBoxGiantIconBox:hover{
        opacity: 0.8;
    }
    .carouseText{
        font-weight: 500;
        margin-top: 1rem;
        font-family: AliKai;
        font-size: 1.5rem;
    }
    .centerBox{
        padding: 1rem 2rem;
        display: flex;flex-direction: column;align-items: center;
        width: calc(100% - 4rem);
        position: absolute;
        top: 40%;
        height: 50%;
    }
    .centerBoxTitle{
        padding: 2rem 0px;
        font-family: DingDing;
        display: flex;align-items: center;
    }
    .centerBoxTitle p{
        color: #6510AD;
        font-size: 1rem;
    }
    .centerBoxTitle h2{
        color: #6510AD;
        padding: 0px 0.5rem;
        font-size: 1rem;
    }
    .centerBoxTitleZheng{
        text-align: center;
        padding: 1rem 0px;
        font-family: AliHei;
        font-size: 0.7rem;
        font-weight: 500;
    }
    .centerBoxContent{
        color: #666666;
        text-indent: 2rem;
        line-height: 2rem;
        font-size: 1rem;
    }
</style>
