import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

createApp(App).use(store).use(router).mount('#app')
// 创建一个上下文来引入 CSS 文件夹下的所有 .css 文件
const requireCSS = require.context('@/assets/css', true, /\.css$/);
// 遍历上下文中的每个模块，并引入它们
requireCSS.keys().forEach(fileName => {
    requireCSS(fileName);
});
