<template>
  <div class="page">
    <TabBar></TabBar>
    <div class="App-body">
      <router-view/>
    </div>
  </div>
</template>
<script>
  import TabBar from "@/components/TabBar";
  export default {
    name: "app",
    components:{TabBar},
    // 禁止web端屏幕缩放
  }

</script>
<style>
  .page{
  }
  body{
    margin: 0px;
    font-size: 0.14rem;
    font-family: "SourceSansPro-Regular", "微软雅黑";
    color: #000;
    -webkit-text-size-adjust: none;
  }
  p{
    margin: 0px;
  }
  *{
    margin: 0px;
  }
  ul li{
    margin: 0px;
    list-style-type: none;
  }
  input {
    background: none;
    border: none;
    outline: none;
    padding: 0;
    margin: 0;
    font-size: 16px; /* 根据需要调整字体大小 */
    color: currentColor; /* 使用当前文本颜色 */
  }

  input::-webkit-input-placeholder {
    color: transparent;
  }

  input:-moz-placeholder { /* Firefox 18- */
    color: transparent;
  }

  input::-moz-placeholder { /* Firefox 19+ */
    color: transparent;
  }

  input:-ms-input-placeholder {
    color: transparent;
  }
  .flexAlignCenter{
    position: relative;
    display: flex;
    align-items: center;
  }
  @font-face {
    font-family: DingDing;
    src:url('./assets/font/DingTalk-JinBuTi.ttf') format("woff");
  }
  @font-face {
    font-family: AliHei;
  }
</style>
